import {
  IActivitiesFilterPayload,
  IActivityData,
  IActivityOrderPayload,
  IRemoveRepeatingActivityPayload,
} from '../pages/activitiesPage/activitiesInterface';
import { deleteApi, deleteWithParams, get, post, put } from './apiService';

export const getAllActivities = async (data: IActivitiesFilterPayload) => {
  return await get(
    `/activity?date=${data?.date}&sort=${data?.sort}&page=${data?.page}&size=${data?.size}&facilityIds=${data?.facilityIds}`
  );
};
export const getActivityById = async (id: string) => {
  return await get(`/activity/${id}`);
};
export const addActivity = async (data: IActivityData) => {
  return await post(`/activity`, data);
};
export const updateActivity = async (data: IActivityData) => {
  return await put(`/activity/${data?.id}`, data);
};
export const removeActivity = async (
  id: string,
  repeatOption: string,
  date: string
) => {
  return await deleteApi(
    `/activity/${id}?repeatOption=${repeatOption}&dates=${date}`
  );
};
export const getUsersList = async (id: string) => {
  return await get(`/activity/${id}/users`);
};
export const removeRepeatingActivity = async (
  data: IRemoveRepeatingActivityPayload
) => {
  return await deleteWithParams(`/activity/${data?.id}`, {
    dates: data?.date,
    repeatOption: data.repeatOption,
  });
};
export const postOrderChangeActivity = async (data: IActivityOrderPayload) => {
  return await post(`/activity/position-change`, data);
};
